@font-face {
  font-family: "Metropolis";
  src        : url("./Metropolis/eot/Metropolis-Regular.eot"),
    url("./Metropolis/woff2/Metropolis-Regular.woff2") format("woff2"),
    url("./Metropolis/ttf/Metropolis-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Bold";
  src        : url("./Metropolis/eot/Metropolis-Bold.eot"),
    url("./Metropolis/woff2/Metropolis-Bold.woff2") format("woff2"),
    url("./Metropolis/ttf/Metropolis-Bold.ttf") format("truetype");
  font-display: swap;
}

// @media all and (max-width:1440px) and (min-width: 100px) {
//   #content {
//     padding-left: 25px;
//     background: url("../images/zoom_out.jpg") left center no-repeat;
//   }
// }

/* GLOBAL CSS */
html,body {
  width      : 1440px;
  height     : 100%;
  max-width  : 1440px;
  min-width  : 1440px;
  min-height : 808px;
  margin     : 0px;
  font-family: 'Metropolis';
  overflow   : hidden;
}

#root {
  position: absolute;
  top     : 0px;
  right   : 0px;
  bottom  : 0px;
  left    : 0px;
}

#menu {
  height    : 100%;
  background: black;
  position  : relative;
}

.sidebar-column {
  width    : 16%;
  flex     : 0 0 16%;
  max-width: 16%;
  height   : 100%;
}

#content {
  height       : 99.5%;
  background   : #F4F5F7;
  min-width    : 1150px;
  overflow     : hidden;
  align-content: center;
}

#top-bar {
  width     : 100%;
  background: white;
  max-width : 1200px;
}

#content-container {
  width     : 100%;
  margin-top: 1%;
  max-width : 1300px;
}

/* HOME PAGE */
#nav-content{
  width     : 100%;
  background: white;
}

.active{
  color      : white;
  font-weight: bold;
}

.navbar-nav > li {
  float   : left;
  position: relative;
}

.navbar-nav {
  display: flex;
  width  : 100%;
}

.navbar-light {
  overflow: hidden;
}

.navbar-light .navbar-nav .active div::after {
  border-bottom: 2px solid #1C9AEA;
  bottom       : 3px;
  content      : " ";
  left         : 0;
  right        : 0;
  position     : absolute;
  width        : 100%;
}

.navbar-custom {
  height    : 34px;
  max-height: 34px;
}

.nav-item {
  margin-left : 10px;
  padding-left: 10px;
}

.nav-item-filter {
  padding-left  : 5px;
  display       : flex;
  flex-direction: row;
  flex-grow     : 1.5;
}

.bold-text {
  color      : #1C9AEA;
  font-size  : 15px;
  font-weight: 900;
  font-style : bolder;
  margin-left: -10px;
  font-family: Metropolis-Bold;
}

#content-top {
  width     : 100%;
  margin-top: 1%;
  background: white;
  text-align: center;
  max-height: 132px;
}

.content-top-left{
  border-right  : 1px solid #333;
  min-height    : 132px;
  max-height    : 132px;
  vertical-align: middle;
  position      : relative;
}

.content-top-left:after {
  padding         : 0;
  margin          : 0;
  content         : "";
  width           : 1px;
  height          : 15px;
  background-color: white;
  position        : absolute;
  right           : -1px;
}

.content-top-left:before {
  padding         : 0;
  margin          : 0;
  content         : "";
  width           : 1px;
  height          : 15px;
  background-color: white;
  position        : absolute;
  right           : -1px;
  bottom          : 0px;
}

#content-main {
  min-width : 100%;
  margin-top: 1.2%;
  text-align: center;
  height    : 100%;
}

#content-main-left{
  width     : 100%;
  height    : 100%;
  position  : relative;
  min-height: 599px;
  position  : relative;
  background: #F4F5F7;
}

#content-main-left-top{
  height      : 100%;
  min-height  : 299px;
  background  : white;
  margin-right: -1%;
  position    : relative;
  min-width   : 250px;
}

#content-main-left-bottom{
  height      : 100%;
  min-height  : 299px;
  margin-top  : 4%;
  background  : white;
  margin-right: 1%;
  position    : relative;
  min-width   : 250px;
}

#content-main-mid{
  text-align   : center;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  max-width    : 700px;
}

#content-main-right{
  text-align   : center;
  width        : 100%;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
}

.home-filter{
  min-width : 690px;
  height    : 34px;
  min-height: 34px;
  flex-grow : 2;
  margin-top: 2px;
}

.home-top{
  max-width : 100%;
  height    : 132px;
  object-fit: cover;
  overflow  : hidden;
}

.home-left{
  width     : 100%;
  height    : 100%;
  min-width : 250px;
  min-height: 299px;
  object-fit: cover;
  position  : absolute;
  align-self: center;
  overflow  : hidden;
}

.home-mid{
  width      : 100%;
  height     : 100%;
  object-fit : cover;
  position   : absolute;
  align-self : center;
  min-width  : 600px;
  overflow   : hidden;
  margin-left: -10px;
}

.home-right{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  overflow  : hidden;
  min-width : 250px;
  background: white;
}

.home-left-custom {
  width     : 22%;
  flex      : 0 0 22%;
  max-width : 22%;
  background: white;
}

.home-mid-custom {
  width       : 50%;
  flex        : 0 0 50%;
  max-width   : 50%;
  background  : white;
  margin-right: 1.5%;
  margin-left : 1.5%;
}

.home-right-custom {
  width     : 25;
  flex      : 0 0 25%;
  max-width : 25%;
  background: white;
}

.box-shadow {
  box-shadow: 0 0 5px 1px lightgray;
}

/* SIDEBAR */
#menu-logo{
  text-align   : center;
  margin-top   : 10%;
  margin-left  : 10%;
  margin-bottom: 5%;
  padding      : 2px;
}

#nav-left{
  color    : #676767;
  font-size: medium;
}

#nav-logos{
  width       : 20px;
  max-height  : 20px;
  margin-left : -10%;
  margin-right: 10%;
  margin-top  : 5.5%;
}

#nav-logos-bottom{
  width       : 20px;
  margin-left : -20px;
  margin-right: 20px;
}

.sidebar {
  height: 100%;
}

.sidebar-content {
  align-self: flex-start;
  height    : 45%;
}

.sidebar a{
  color    : #676767;
  font-size: 15px;
}

.sidebar a:hover{
  color    : white;
  font-size: 15px;
}

.sidebar-main{
  margin-top   : 8%;
  margin-bottom: 0;
}

.sidebar-main-first{
  margin-bottom: 0;
}

.sidebar-main-last{
  margin-top: 8%;
}

.sidebar-bottom{
  margin-bottom: 0;
}

.sidebar-drop a{
  margin-top : -5%;
  margin-left: 0%;
}

.sidebar-active a{
  color      : white;
  font-weight: bolder;
  font-size  : medium;
}

.textWhite {
  color      : white !important;
  font-weight: bolder !important;
  font-style : bold !important;
  font-family: Metropolis-Bold;
}

.nav-active a::before {
  border-left: 3px solid #B8278F;
  left       : -40px;
  top        : 0;
  bottom     : 0;
  content    : " ";
  position   : absolute;
  height     : 40px;
}

/* SALES BY AREA PAGE */
#content-sales-area-mid{
  text-align   : center;
  min-height   : 750px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-left  : -1.5%;
}

#content-sales-area-right{
  text-align   : center;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
}

.sales-area-mid{
  width     : 100%;
  height    : 100%;
  min-height: 700 px;
  object-fit: cover;
  position  : absolute;
}

.sales-area-right{
  min-width : 100%;
  min-height: 100%;
  object-fit: cover;
  position  : absolute;
  overflow  : hidden;
}

/* SALES BY SHIPPING PAGE */
#content-sales-shipping-mid{
  text-align   : center;
  min-height   : 750px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-left  : -1.5%;
}

#content-sales-shipping-right{
  text-align   : center;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
}

.sales-shipping-mid{
  width     : 100%;
  height    : 100%;
  min-height: 665px;
  object-fit: cover;
  position  : absolute;
}

.sales-shipping-right{
  min-width : 100%;
  min-height: 100%;
  object-fit: cover;
  position  : absolute;
}

/* SALES DAILY DATA PAGE */
#content-sales-daily{
  text-align   : center;
  min-height   : 800px;
  max-height   : 800px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-top   : -1%;
}

.sales-daily{
  width      : 100%;
  height     : 100%;
  min-height : 750px;
  object-fit : cover;
  position   : absolute;
  margin-left: -2.5%;
}

/* CUSTOMER ACQUISITION */
#content-customer-acquisition{
  text-align   : center;
  min-height   : 740px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-top   : 0.5%;
}

.customer-acquisition{
  width      : 100%;
  height     : 100%;
  min-height : 645px;
  object-fit : cover;
  position   : absolute;
  margin-left: -2.5%;
  overflow   : hidden;
}

// CUSTOMER COHORT PAGE
.box{
  display        : block;
  box-sizing     : border-box;
  border         : 15px solid lightgray;
  background-clip: padding-box;
}

#content-customer-cohort-mid{
  width        : 100%;
  text-align   : center;
  min-height   : 530px;
  background   : white;
  overflow     : scroll;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-left  : -12px;
}

#content-customer-cohort-right{
  width        : 100%;
  text-align   : center;
  min-height   : 100%;
  background   : white;
  overflow     : scroll;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-right : -50px;
}

.customer-cohort-mid{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

.customer-cohort-right{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

#content-customer-cohort-bottom-mid{
  width        : 100%;
  text-align   : center;
  min-height   : 210px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-top   : 10px;
  margin-left  : -12px;
}

#content-customer-cohort-bottom-right{
  width        : 100%;
  text-align   : center;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-top   : 10px;
  margin-right : -50px;
}

.customer-cohort-bottom-mid{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

.customer-cohort-bottom-right{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

// CUSTOMER BASKET SIZE PAGE
#content-basket {
  width     : 100%;
  height    : 100%;
  background: #F4F5F7;
  overflow-y: auto;
  overflow-x: hidden;
}

#content-main-basket {
  max-width   : 100%;
  margin-top  : 1.2%;
  text-align  : center;
  height      : 100%;
  margin-left : -12px;
  margin-right: 12px;
}

#content-basket-mid{
  text-align   : center;
  height       : 750px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
}

.customer-basket-mid{
  width     : 100%;
  max-height: 750px;
  object-fit: cover;
  position  : absolute;
  align-self: center;
}

#content-basket-right{
  position    : relative;
  max-height  : 750px;
  margin-right: -15px;
}

#customer-basket-right-top{
  max-width : 100%;
  min-height: 370px;
  background: white;
  position  : relative;
  overflow  : hidden;
}

#customer-basket-right-bottom{
  max-width : 100%;
  min-height: 370px;
  margin-top: 3.5%;
  background: white;
  position  : relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.customer-basket-right{
  width      : 100%;
  height     : 100%;
  max-height : 370px;
  object-fit : cover;
  margin-left: 0;
}

.customer-basket-right-custom {
  width       : 25%;
  flex        : 0 0 25%;
  max-width   : 25%;
  margin-left : 15px;
  margin-right: -15px;
}

#header-modal-zoom-out {
  background-color: black;
  color           : white;
  font-family     : Metropolis-Bold;
  font-weight     : bolder;
}

#header-modal-zoom-out > button > span {  
  color: white;
}

// CUSTOMER GENDER PAGE
.box{
  display        : block;
  box-sizing     : border-box;
  border         : 15px solid lightgray;
  background-clip: padding-box;
}

#content-customer-gender-mid{
  max-width    : 87%;
  text-align   : center;
  min-height   : 320px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-left  : -12px;
}

#content-customer-gender-right{
  min-width    : 63.2%;
  min-height   : 100%;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  text-align   : center;
  margin-left  : -5%;
}

.customer-gender-mid{
  width     : 100%;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

.customer-gender-right{
  min-width : 750px;
  height    : 100%;
  object-fit: cover;
  position  : absolute;
}

#content-customer-gender-bottom-mid{
  width        : 100%;
  text-align   : center;
  min-height   : 500px;
  background   : white;
  overflow     : hidden;
  position     : relative;
  align-items  : center;
  align-content: center;
  margin-top   : 10px;
  margin-right : -10%;
  padding-left : 15px;
  padding-right: -10%;
}

.customer-gender-bottom-mid{
  width        : 100%;
  height       : 80%;
  object-fit   : cover;
  position     : absolute;
  margin-bottom: 2%;
  margin-right : -50px;
}